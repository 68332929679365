import type { ErrorIdent } from '~/@types/errorIdents';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type {
  Result,
  postBody,
} from '~/server/api/[site]/user/account/changePw.post';
import { useSessionStore } from '~/stores/useSessionStore';
import { handleLoadingError } from '~/utils/handleLoadingError';

interface resetPasswordForm {
  password: {
    password: string;
    passwordConfirm: string;
  };
  oldPassword: string;
}

export function useChangePasswordForm() {
  const isLoading = ref(false);
  const showSuccess = ref(false);
  const errors = ref<ErrorIdent[]>([]);
  const site = useSiteIdent();
  const session = useSessionStore();

  async function onSubmit(data: resetPasswordForm) {
    isLoading.value = true;
    await resetPassword(data);
    isLoading.value = false;
  }

  async function resetPassword(formData: resetPasswordForm) {
    try {
      isLoading.value = true;
      (document.activeElement as HTMLElement)?.blur();
      const postData: postBody = {
        newPassword: formData.password.password,
        newPasswordConfirm: formData.password.passwordConfirm,
        oldPassword: formData.oldPassword,
      };

      const result = await useSecureSessionPost<Result>(
        `/api/${site}/user/account/changePw`,
        postData,
      );
      if (result?.sessionId) session.login(result.sessionId);
      showSuccess.value = true;
      isLoading.value = false;
    } catch (e: any) {
      errors.value = e.data.data.errors;
      document.querySelector('.js-error-container')?.scrollIntoView({
        behavior: 'smooth',
      });
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  return {
    isLoading,
    onSubmit,
    errors,
    showSuccess,
  };
}
